<template>
    <b-container class="m-o w-100" fluid>
      <header-slot>
        <template #actionsButtons>
          <portal-target name="actionsButtons_header" />
        </template>
        <template #actions>
          <!-- This component is using PortalVue, look for the action buttons in the corresponding components -->
          <portal-target name="actions_header"> </portal-target>
        </template>
      </header-slot>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: 'debtsolution-settlements' }"
          exact
          exact-active-class="active"
          :active="$route.matched[2].name == 'debtsolution-settlement-acount-actions'"
          :link-classes="['px-3', bgTabsNavs]"
          >SETTLEMENTS
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'debtsolution-accounts-actions' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="$route.matched[2].name == 'debtsolution-accounts-actions'"
          >ACCOUNTS ACTIONS
        </b-nav-item>
      </b-nav>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view />
      </b-card>
    </b-container>
  </template>
  
  <script>
  export default {
  };
  </script>